import React, { Component } from "react"

import SocialItem from "components/SocialItem"

import styles from "./footer.module.scss"

const Contact = props => (
  <div className={styles.contactContainer}>
    <span className={styles.item}>Zomerdijk 50B</span>
    <span className={styles.divider}>|</span>
    <span className={styles.item}>3143 CT, Maassluis</span>
    <span className={styles.divider}>|</span>
    <a href="tel:+31103216074" alt="Phone" className={styles.item}>
      +31 10 321 60 74
    </a>
    <span className={styles.divider}>|</span>
    <a href="mailto:hoihoi@keytoe.nl" alt="Email" className={styles.item}>
      hoihoi@keytoe.nl
    </a>
  </div>
)

class Footer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { type } = this.props
    return (
      <div
        className={[styles.footer, type ? styles[`${type}`] : null].join(" ")}
      >
        <div className={[styles.wrapper, "wrapper"].join(" ")}>
          <div className={styles.social}>
            <SocialItem
              link="https://www.linkedin.com/company/keytoe/"
              text="LinkedIn"
            />
            <span className={styles.divider}>/</span>
            <SocialItem
              link="https://www.instagram.com/keytoenl/"
              text="Instagram"
            />
            <span className={styles.divider}>/</span>
            <span className={styles.divider}>/</span>
            <SocialItem
              link="https://www.youtube.com/channel/UCUdxydVCUX3wvq2q3_NqT-A"
              text="YouTube"
            />
            <span className={styles.divider}>/</span>
            <SocialItem
              link="https://open.spotify.com/show/2L4WEdnglXtsO7VfX74gAq"
              text="Podcast"
            />
          </div>
          <Contact />
        </div>
      </div>
    )
  }
}

export default Footer
